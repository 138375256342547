import {
  FieldModel,
  ProgramEngagementModel,
  ProgramModel,
  ProgramTaskRole,
  UserId,
} from '@cibo/core'
import { useBatchedFields } from '@cibo/landmanager'
import { useAuth } from '@cibo/profile'
import { EngagementRouteParams } from '@cibo/ui'
import { UseQueryResult, useQueryClient } from '@tanstack/react-query'
import { createContext, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useProgramEngagementDetails } from '../../hooks/useProgramEngagementDetails'
import { PROGRAMS_QUERY_KEY, useProgramEngagement, useProgramModel } from '../../queries'

type ProgramEngagementState = EngagementRouteParams & {
  basePath: string
  program: UseQueryResult<ProgramModel | null, unknown>
  ownerName: string
  advisorName?: string
  advisorEmail?: string

  dataEntryDetail(spec: { parentTraitId: string; traitId: string }): any

  engagement: UseQueryResult<ProgramEngagementModel | null, unknown>
  /** @deprecated - prefer using `engagement.refetch()` */
  refreshEngagement(): void

  engagementFields: UseQueryResult<FieldModel[] | undefined, Error>
  /** @deprecated prefer using `engagementFields` for state & refetch access */
  fields?: FieldModel[]

  continueFunction: Function | undefined
  setContinueFunction(f: Function | undefined): void

  userRole: ProgramTaskRole
  userId?: UserId
}

const DEFAULT_VALUES = {
  basePath: '',
  ownerName: '',
  engagement: {} as UseQueryResult<ProgramEngagementModel | null, unknown>,
  userRole: 'participant',
  program: {} as UseQueryResult<ProgramModel | null, unknown>,
  continueFunction: undefined,
  setContinueFunction: () => console.log('default setContinueFunction()'),
  refreshEngagement: () => console.log('default refreshEngagement()'),
  refreshFields: () => console.log('default refreshFields()'),
  dataEntryDetail: () => console.log('default dataEntryDetail()'),
  engagementFields: {} as UseQueryResult<FieldModel[] | undefined, Error>,
} as ProgramEngagementState

export const ProgramEngagementContext = createContext<ProgramEngagementState>(DEFAULT_VALUES)
ProgramEngagementContext.displayName = 'ProgramEngagementContext'

type ProgramEngagementProviderProps = {
  basePath: string
  children: React.ReactNode
  engagementId?: string
  programId: string
}

export const ProgramEngagementProvider = ({
  basePath,
  children,
  programId,
}: ProgramEngagementProviderProps) => {
  const { t } = useTranslation('@cibo/programs/ProgramEngagementProvider')

  const { engagementId, stageId = 'apply', stepId, taskId } = useParams<EngagementRouteParams>()

  const engagement = useProgramEngagement({ id: engagementId })
  const program = useProgramModel({ programId: engagement.data?.programId ?? programId })
  const engagementFields = useBatchedFields(engagement.data?.fields ?? [])
  const { userId: selfId } = useAuth()
  const userRole = selfId === engagement.data?.ownedBy?.userId ? 'participant' : 'manager'
  const ownerName = engagementFields.data?.[0]?.ownerLabel ?? (t('applicant') as string)
  const userId = engagement.data?.ownedBy?.userId

  const details = useProgramEngagementDetails({
    resourceId: engagementId,
  })

  const queryClient = useQueryClient()

  const refreshEngagement = () => {
    queryClient.invalidateQueries({
      queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS, engagementId],
    })
  }

  const [continueFunction, setContinueFunction] = useState<Function | undefined>()

  return (
    <ProgramEngagementContext.Provider
      value={{
        engagementId,
        stageId,
        stepId,
        taskId,

        advisorEmail: engagement.data?.relevantUserInfo?.advisor?.userPrimaryEmail,
        advisorName: engagement.data?.relevantUserInfo?.advisor?.label,
        basePath,
        dataEntryDetail: ({ parentTraitId, traitId }) => {
          const parentDetail = details.data?.find(({ traitId }) => traitId === parentTraitId)
          // @ts-ignore not sure how to type dataEntry details yet
          return parentDetail?.result?.[traitId]
        },
        program,
        refreshEngagement,
        ownerName,
        engagement,
        userRole,
        userId,
        fields: engagementFields.data,
        // @ts-ignore Not a complete useQuery - it's a combination of a few
        engagementFields,
        continueFunction,
        setContinueFunction: (f: Function | undefined) => {
          // useState interprets a function as an updater,
          // but we want the new state to be the function
          setContinueFunction(() => f)
        },
      }}
    >
      {children}
    </ProgramEngagementContext.Provider>
  )
}

export const useProgramEngagementContext = () => useContext(ProgramEngagementContext)
