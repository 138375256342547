import { IntegerString } from '@cibo/core'
import { usePracticesContent } from '@cibo/landmanager'
import { AuthCheck, useAuth } from '@cibo/profile'
import { Box, Stack, Typography } from '@mui/material'
import Image from 'mui-image'
import { flatten, path, prop, uniq } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { ProgramEngagementProvider, useProgramEngagementContext } from '../../context'
import { useProgramEngagement } from '../../queries'
import { useEqipEngagementFieldsWithConcerns } from '../../queries/useEqipRecommendations'
import imgUrl from './CiboLogo.svg'
import { listString } from './listString'
import { PdfLoadingIndicator } from './PdfLoadingIndicator'
import { SelectedPracticesTable } from './SelectedPracticesTable'
import { usePracticeRecommendationContent } from './usePracticeRecommendationContent'

export const EqipSelectedPracticesPDF = () => {
  const { engagementId } = useParams()
  const { isLoggedIn } = useAuth()

  const engagement = useProgramEngagement(
    { id: engagementId },
    { enabled: !!engagementId && isLoggedIn }
  )

  // populate user session state from jwt
  if (!isLoggedIn) return <AuthCheck />

  if (!engagementId) return <>@@@NO ENGAGEMENT ID@@@</>

  return (
    <ProgramEngagementProvider basePath={''} programId={engagement.data?.programId ?? ''}>
      <style
        dangerouslySetInnerHTML={{
          __html: `
  @page {
    size: A4 portrait;
    margin: 0.5in;
  }
`,
        }}
      />

      <Header />

      <SelectedPracticesTable engagementId={engagementId} />
    </ProgramEngagementProvider>
  )
}

const Header = () => {
  const { t } = useTranslation('@cibo/programs/EqipSelectedPracticesPDF')
  const { engagement, ownerName, advisorName, advisorEmail, dataEntryDetail } =
    useProgramEngagementContext()
  const fieldModels = useEqipEngagementFieldsWithConcerns(engagement.data?.id)
  const practicesContent = usePracticesContent()

  const selectedPracticeIds =
    fieldModels.data && uniq(flatten(fieldModels.data.map(prop('selectedPractices'))))

  const practiceRecommendationContent = usePracticeRecommendationContent()

  const selectedPracticeItems = selectedPracticeIds?.map(practiceId =>
    practiceRecommendationContent.data?.items.find(
      practice => practice.fields.practiceId === practiceId
    )
  )

  const selectedPracticeNames = selectedPracticeItems?.map(path(['fields', 'name'])) as string[]

  const selectedPractices = selectedPracticeNames
    ? listString(selectedPracticeNames)
    : t('practices')

  const includesPractice590 = selectedPracticeIds?.includes('590' as IntegerString)

  const advisorNote = dataEntryDetail({
    parentTraitId: 'wfQuestionsV1',
    traitId: 'advisorNoteToNRCS',
  })?.value

  if (
    fieldModels.isPending ||
    practicesContent.isPending ||
    practiceRecommendationContent.isPending
  ) {
    return <PdfLoadingIndicator />
  }

  if (practicesContent.isError) {
    console.error(practicesContent.error)
  }

  if (fieldModels.isError || practicesContent.isError || practiceRecommendationContent.isError) {
    return <>error</>
  }

  return (
    <Stack style={{ pageBreakAfter: 'always' }} spacing={3}>
      <Box style={{ width: '150px' }}>
        <Image src={imgUrl} style={{ display: 'block', width: '150px' }} />
      </Box>

      <Typography variant="h6">{t('title', { farmerName: ownerName })}</Typography>

      {!!advisorName && !!advisorEmail && (
        <Typography>
          {t('advisorText', { farmerName: ownerName, advisorName, advisorEmail })}
        </Typography>
      )}

      {!!advisorNote && advisorNote !== '' && (
        <Stack>
          <Typography variant="h6">{t('advisorNoteTitle')}</Typography>
          <Typography>{advisorNote}</Typography>
        </Stack>
      )}

      <Typography>{t('intro', { farmerName: ownerName, selectedPractices })}</Typography>

      {!!includesPractice590 && <Typography>{t('nutrientManagementWarning')}</Typography>}

      <Typography>{t('cta', { farmerName: ownerName })}</Typography>
    </Stack>
  )
}
