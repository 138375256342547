import { Entry } from 'contentful'
import { eqProps, pluck, propEq, uniq, uniqWith } from 'ramda'
import {
  BaseProgramTaskContent,
  Benchmark,
  DetailRequirement,
  EngageType,
  OrgStatusSummary,
  ProgramBenchmarkName,
  ProgramConfig,
  ProgramContent,
  ProgramStep,
  ProgramStepContent,
  ProgramTask,
  ProgramTaskContent,
  RewardToken,
  TraitId,
} from '../types'
import { ProgramBinding, ProgramStage } from '../types/Program/Binding'

export class ProgramModel implements Partial<ProgramConfig> {
  programId: string
  program: ProgramConfig
  orgStatusSummary?: OrgStatusSummary
  engageType: EngageType
  rulesVersion?: 'v1' | 'v2' | undefined

  constructor(params: ProgramConfig & { content: Entry<ProgramContent> }) {
    this.programId = params.programId
    this.orgStatusSummary = params.orgStatusSummary
    this.engageType = params.engageType
    this.rulesVersion = params.rulesVersion

    this.program = params
  }

  get benchmarks() {
    return this.program.benchmarks
  }

  get isOpenForEnrollment() {
    return this.program.enrollmentWindow.enrollmentStatus === 'open'
  }
  get isClosedForEnrollment() {
    return this.program.enrollmentWindow.enrollmentStatus === 'closed'
  }
  get isFuture() {
    return this.program.enrollmentWindow.enrollmentStatus === 'future'
  }

  get isEnrolling() {
    return this.program.enrollmentWindow.isEnrolling
  }

  get enrollmentCloseDate() {
    return this.program.enrollmentWindow.enrollmentEnd
  }

  get closeDate() {
    return this.program.enrollmentWindow.closeDate
  }

  hasBenchmark(benchmark: Benchmark) {
    return !!this.program.benchmarks?.some(item => item.benchmark === benchmark)
  }

  hasProfile() {
    return this.hasBenchmark('profile')
  }

  requiredDetails(benchmark: ProgramBenchmarkName) {
    const { benchmarks } = this.program
    if (!benchmarks || !benchmarks.find(propEq('benchmark', benchmark))) {
      return []
    }

    let details: DetailRequirement[] = []

    const compatible = (a: DetailRequirement, b: DetailRequirement) =>
      eqProps('traitId', a, b) && eqProps('year', a, b)

    for (let i = 0, l = benchmarks.length; i < l; ++i) {
      details = uniqWith(compatible)([...benchmarks[i].details, ...details])

      if (benchmarks[i].benchmark === benchmark) {
        break
      }
    }

    return details
  }

  requiredTraits(benchmark: ProgramBenchmarkName) {
    const details: Array<{ traitId: TraitId }> = this.requiredDetails(benchmark) || []
    return uniq<TraitId>(pluck('traitId', details))
  }

  yearsForTrait(benchmark: ProgramBenchmarkName, traitId: TraitId) {
    const requirements = this.requiredDetails(benchmark)
    const details = requirements?.filter(propEq('traitId', traitId)) || []
    const years: number[] = []
    details.forEach(detail => {
      if (detail.year) {
        years.push(detail.year)
      }
    })

    return years.length ? years : undefined
  }

  orgSaturation() {
    return 0
    // return this.program.resourceCounts.find(({entity}) => entity === 'org').
  }

  earnedToken(token: RewardToken) {
    const amount = this.program.earned?.find(r => r.token === token)?.amount
    return amount === undefined ? 0 : amount
  }

  stage(id: ProgramStage['id']) {
    for (const node of this.program.stages ?? []) {
      const stage = findNode<ProgramStage>({ id, node })
      if (stage) {
        return stage
      }
    }
  }

  step(id?: ProgramStep['id']) {
    if (id) {
      for (const node of this.program.stages ?? []) {
        const step = findNode<ProgramStep>({ id, node })
        if (step) {
          return step
        }
      }
    }
  }

  stepContent(id?: ProgramStepContent['id']) {
    if (id && Array.isArray(this.program.content?.fields.stages)) {
      for (const node of this.program.content?.fields.stages) {
        const step = findNode<ProgramStepContent>({ id, node })
        if (step) {
          return step
        }
      }
    }
  }

  task(id?: ProgramTask['id']) {
    if (id) {
      for (const node of this.program.stages ?? []) {
        const task = findNode<ProgramTask>({ id, node })
        if (task) {
          return task
        }
      }
    }
  }

  taskContent<T extends BaseProgramTaskContent = BaseProgramTaskContent>(
    id?: ProgramTaskContent['id']
  ) {
    if (id && Array.isArray(this.program.content?.fields.stages)) {
      for (const node of this.program.content?.fields.stages) {
        const task = findNode<ProgramTaskContent<T>>({ id, node })
        if (task) {
          return task
        }
      }
    }
  }

  stepTasksHiddenViaContentful(stepId?: ProgramStep['id']) {
    return !!stepId
      ? this.stepContent(stepId)
          ?.children?.filter(({ hidden }: ProgramTaskContent) => hidden)
          .map(({ id }) => id)
      : []
  }

  stepTasksNotHiddenViaContentful(stepId?: ProgramStep['id']) {
    return !!stepId
      ? this.stepContent(stepId)
          ?.children.filter(({ hidden }: ProgramTaskContent) => !hidden)
          .map(({ id }) => id)
      : []
  }

  get hasTiers() {
    return this.program.tiers && this.program.tiers.length > 1
  }

  get promoImages() {
    return this.program.content?.fields.promoImages
  }

  get title() {
    return this.program.content?.fields.title
  }

  get hasGrowerLimits() {
    return !!this.program.ledger.maxAcresPerGrower || !!this.program.ledger.maxFieldsPerGrower
  }

  get growerLimits() {
    return {
      acres: this.program.ledger.maxAcresPerGrower,
      fields: this.program.ledger.maxFieldsPerGrower,
    }
  }
}

export const findNode = <T = ProgramStage | ProgramStep | ProgramTask>({
  id,
  node,
}: {
  id: string
  node: ProgramBinding
}): T | undefined => {
  if (node.id === id) {
    return node as T
  }

  let result: T | undefined
  for (const child of node.children ?? []) {
    result = findNode<T>({ id, node: child })
    if (result) return result
  }
}

//@ts-ignore TODO: why is this function type complaining when findNode is not????
export const parentNode = <T = ProgramStage | ProgramStep | ProgramTask>({
  id,
  node,
}: {
  id: string
  node: ProgramBinding
}) => {
  if (node.children?.find(propEq('id', id))) {
    return node as T
  }

  let result: T | undefined
  for (const child of node.children ?? []) {
    result = parentNode<T>({ id, node: child })
    if (result) return result
  }
}
